import React from 'react';

import Layout from '../components/Layout';
import launchVideoWebm from '../assets/videos/launch-video.webm';
import launchVideoMp4 from '../assets/videos/launch-video.mp4';
import launchVideoPoster from '../assets/images/launch-video-poster.png';
import launchVideoMobileMp4 from '../assets/videos/launch-video-square.mp4';
import launchVideoMobileWebm from '../assets/videos/launch-video-square.webm';
import launchVideoMobilePoster from '../assets/images/launch-video-square-poster.png';

const OurVision = () => {
  return (
    <Layout className="our-vision">
      <img />
      <video
        className="desktop"
        controls
        loop
        preload="auto"
        poster={launchVideoPoster}
      >
        <source src={launchVideoWebm} type="video/webm; codecs=vp9,vorbis" />
        <source src={launchVideoMp4} type="video/mp4" />
      </video>
      <video
        className="mobile"
        controls
        loop
        preload="auto"
        poster={launchVideoMobilePoster}
      >
        <source src={launchVideoMobileWebm} type="video/webm; codecs=vp9,vorbis" />
        <source src={launchVideoMobileMp4} type="video/mp4" />
      </video>
    </Layout>
  );
};

export default OurVision;
